import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Cta from '../components/cta'
import { TemplateProps } from '../utils/types'

export const query = graphql`
  query OurDeals($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      navigationTitle
      slug
      metadataTitle
      metadataDescription
      pageHero {
        ...CtaData
      }
    }
  }
`

const OurDeals: React.FC<TemplateProps> = ({
  data: { page },
  pageContext: {
    navigation,
    footerNavigation,
    helpNavigation,
    ourStoryNavigation,
  },
}) => {
  const pageHero = page?.pageHero

  return (
    <Layout
      page={page}
      navigation={navigation}
      footerNavigation={footerNavigation}
      helpNavigation={helpNavigation}
      ourStoryNavigation={ourStoryNavigation}
    >
      {pageHero && <Cta {...pageHero} />}
      <iframe
        frameBorder="0"
        scrolling="no"
        src={process.env.GATSBY_LIFECYCLE_IFRAME_URL}
        width="100%"
        sx={{
          marginTop: '2rem',
          display: 'block',
          width: '100%',
          maxWidth: '84rem',
          marginX: 'auto',
          overflow: 'auto',
          height: '200rem',
          '@media screen and (min-width: 37.5rem)': {
            height: '175rem',
          },
          '@media screen and (min-width: 75rem)': {
            height: '93.75rem',
            minWidth: '75rem',
          },
        }}
      />
    </Layout>
  )
}

export default OurDeals
